import Modal from "@/components/Modal";
import React, {useState} from "react";
import {ISkuItem} from "./interface";
import './index.less'
import GoodsTable from "@/pages/createOrder/components/skuModal/goodsList";

interface Props {
    onSelect?: (params: ISkuItem[]) => void
    visible: any
    setVisible: any
}

const SkuModal = (props: Props) => {

    const {onSelect, visible, setVisible} = props;
    const [selects, setSelects] = useState<ISkuItem[]>([])
    const getCheckItems = ()=> {
        if (selects.length > 0) {
            onSelect?.(selects)
        }
        setVisible(-1);
    }


    return (
            <Modal visible={visible > -1} width={1150} onCancel={() => setVisible(-1)} footer={false}
                   maxHeight='85vh'>
                <div className="skuModalTable">
                    <GoodsTable visible={visible} setSelects={setSelects}/>
                </div>
                <div className="flex mt20 justify-center sku-modal-op">
                    <button className="zy-button mr10" onClick={getCheckItems}>确认选择</button>
                    <button className="ghost-button" onClick={() => setVisible(-1)}>取消</button>
                </div>
            </Modal>
    )
}

export default SkuModal